<template>
  <div class="Z3ViewBGView">
    <ul v-if="viewStyle=='list'" class="infinite-list-wrapper viewList" v-infinite-scroll="updateMsgList"
        style="overflow:scroll"
    >
      <li v-for="(item, index) in dataList"
          class="infinite-list-item viewLi"
          :infinite-scroll-disabled="disabled"
          @click="clickItem(item)"
      >
        <z3-cell :z3-info="item" :order="'#' + (index + 1)"></z3-cell>
      </li>
    </ul>
    <div v-if="viewStyle=='rssi'||viewStyle=='mega'" class="rssiView">
      <device-charts :data-keys="this.dataKeys" :data-list="this.chartDataList" :data-line-name="this.lineName"></device-charts>
    </div>
  </div>
</template>

<script>
import Z3Cell from '@/views/deviceMQ/Z3View/z3Cell'
import MQmixin from '@/views/deviceMQ/MQmixin/MQmixin'
import DeviceCharts from '@/views/deviceMQ/deviceCharts/deviceCharts'

export default {
  name: 'Z3View',
  components: { DeviceCharts, Z3Cell },
  mixins: [MQmixin],
  computed: {
    lineName: function() {
      if (this.viewStyle == 'rssi') {
        return 'rssi'
      } else if (this.viewStyle == 'mega') {
        return '磁感线'
      }
      return ''
    },
    chartDataList:function() {
      if (this.viewStyle == 'rssi') {
        return this.dataList;
      } else if (this.viewStyle == 'mega') {
        let list = [];
        for (let i = 0; i < this.dataList.length; i++) {
          let data = this.dataList[i];
          if (typeof data['datetime'] == 'undefined') {
            continue;
          }
          let startTime = data['datetime'];
          let key = 'data_'
          for (let j = 6; j >= 1; j--) {
            let valueKey = key + j;
            let value = 0;
            if (typeof data[valueKey] != 'undefined' && data[valueKey] != null) {
              value = parseFloat(data[valueKey]);
            }
            list.push({
              datetime:startTime + (j - 6) * 10 * 1000,
              rssi:value,
            })
          }
        }
        return list
      }
      return []
    },
    dataKeys: function() {
      if (this.viewStyle == 'rssi') {
        return ['datetime', 'rssi']
      } else if (this.viewStyle == 'mega') {
        return ['datetime', 'rssi']
      }
      return []
    }
  },
  data() {
    return {
      viewType: 'Z3',
      loadingDivId: 'z3Loading',
      urlBlock: (config) => {
        for (const key in this.query) {
          let value = this.query[key]
          if (typeof config[key] == 'undefined' || key == 'time') {
            config[key] = value
          } else {
            config[key] = config[key] == '' ? value : config[key] + ',' + value
          }
        }
        return this.$api.deviceMQ.getZ3MsgList(config)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {

    })
  }
}
</script>

<style lang="scss" scoped>

.Z3ViewBGView {
  width: 100%;
  height: 100%;

  .viewList {
    width: calc(100%);
    height: calc(100%);
    list-style: none;
    padding: 0;

    .viewLi {
      width: calc(100%);
      margin-bottom: 5px;
    }
  }

  .rssiView {
    width: 100%;
    height: calc(100% - 50px);
    margin-bottom: 10px;
  }
}

</style>
