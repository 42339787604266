<template>
  <div class="z3CellBGView">
    <device-msg-head
        :order="order"
        device-type="Z3"
        :device-mac="z3Info.macAddr"
        :device-station-mac="'基站:' + z3Info.baseMac"
    >
    </device-msg-head>
    <div style="height: 5px"></div>
    <device-msg-cell v-for="(key) in dataList" :key="key" :content-str="key + ':' + dataMsg(key)">
    </device-msg-cell>
    <device-msg-footer :rssi="dataMsg('rssi')" :time-stamp="dataMsg('datetime')"></device-msg-footer>
    <div style="width: 95%;height: 1px;background-color: #cccccc;margin: 5px 0px 5px 0px"></div>
  </div>
</template>

<script>
import DeviceMsgHead from '@/views/deviceMQ/deviceMsg/deviceMsgHead'
import DeviceMsgCell from '@/views/deviceMQ/deviceMsg/deviceMsgCell'
import DeviceMsgFooter from '@/views/deviceMQ/deviceMsg/deviceMsgFooter'

export default {
  name: 'z3Cell',
  components: { DeviceMsgFooter, DeviceMsgCell, DeviceMsgHead },
  props: {
    order: {
      type: String,
      default: () => {
        return '#'
      }
    },
    z3Info: {
      type: Object,
      default: () => {
        return {
          'baseMac': '',
          'battery': 0,
          'data_1': 0,
          'data_2': 0,
          'data_3': 0,
          'data_4': 0,
          'data_5': 0,
          'data_6': 0,
          'datetime': 0,
          'deviceId': '',
          'deviceStatus': 0,
          'dropdown': '',
          'id': '',
          'macAddr': '',
          'num': 0,
          'rssi': 0,
          'start': true,
          'status': 0,
          'type': 0,
          'version': ''
        }
      }
    }
  },
  computed: {
    dataList() {
      return ['baseMac',
        'macAddr',
        'battery',
        'data_1',
        'data_2',
        'data_3',
        'data_4',
        'data_5',
        'data_6',
        'deviceStatus',
        'dropdown',
        'num',
        'rssi',
        'start',
        'status',
        'type',
        'version'
      ]
    }
  },
  methods: {
    dataMsg: function(key) {
      if (typeof this.z3Info[key] == 'undefined' || this.z3Info[key] == null) {
        return ''
      }
      return this.z3Info[key]
    }
  }
}
</script>

<style lang="scss" scoped>

.z3CellBGView {
  width: 100%;
}

</style>
